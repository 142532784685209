import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bench Press 8-8-8\\@75% 1RM`}</p>
    <p>{`Strict Pullups 8-8-8 (weighted if possible)`}</p>
    <p>{`then,`}</p>
    <p>{`15:00 AMRAP of:`}</p>
    <p>{`10-Pullups`}</p>
    <p>{`15-Pushups`}</p>
    <p>{`25ft Bear Crawl`}</p>
    <p>{`10-Pullups`}</p>
    <p>{`15-Pushups`}</p>
    <p>{`50ft Bear Crawl`}</p>
    <p>{`10-Pullups`}</p>
    <p>{`15-Pushups`}</p>
    <p>{`75ft Bear Crawl`}</p>
    <p>{`etc.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Today is the last day to get your teams registered for True Grit 6
and get your shirts on the day of the competition.  We will accept late
registrations until Sept 8th but you will not get your shirts on the
15th.  Get your team signed up today!    Competition date is September
15th. If you’d like to compete but don’t have a team we have a signup
sheet by the white board. You can also email us to get on a team. If
you’d like to help judge please add your name to the sheet.  We still
need several more judges.  `}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong"><a parentName="em" {...{
            "href": "https://www.eventbrite.com/e/true-grit-6-tickets-48356197689"
          }}>{`https://www.eventbrite.com/e/true-grit-6-tickets-48356197689`}</a></em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`For more info go to: truegritcomp.wordpress.com`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`*`}{`This Monday we will have our free Labor Day workout at 10:30am.
 Everyone is invited so bring a friend!  All other classes and open gym
are cancelled for the day.   After the class all members and their
families are invited to the Barnetts house to swim, cookout and relax!
 Email, call or text for directions or more info.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      